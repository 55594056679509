var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('nssfReport.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('nssfReport.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"row mb-3"},[_c('div',[_c('label',{staticClass:"col-label form-label tw-text-xs required"},[_vm._v(_vm._s(_vm.$t('nssfReport.organizationalStructure')))]),_c('Select',{class:{
                                    'is-invalid': _vm.errors.has(
                                        'org_chart_structure_id'
                                    )
                                },model:{value:(_vm.org_chart_structure_id),callback:function ($$v) {_vm.org_chart_structure_id=$$v},expression:"org_chart_structure_id"}},_vm._l((_vm.orgChartStructure),function(org,index){return _c('Option',{key:index,attrs:{"value":org.org_chart_structure_id}},[_vm._v(" "+_vm._s(org.org_chart_structure)+" ")])}),1),(_vm.errors.has('org_chart_structure_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('org_chart_structure_id'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label tw-text-xs required"},[_vm._v(_vm._s(_vm.$t('nssfReport.cycleYear')))]),_c('date-Picker',{class:{
                                    'is-invalid': _vm.errors.has('cycle_year')
                                },attrs:{"value":_vm.cycle_year,"type":"year","placeholder":"Select Year","format":"yyyy"},on:{"on-change":_vm.cycleYearChange}}),(_vm.errors.has('cycle_year'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_year'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-label form-label tw-text-xs required"},[_vm._v(_vm._s(_vm.$t('nssfReport.cycleMonth')))]),_c('date-Picker',{class:{
                                    'is-invalid': _vm.errors.has('cycle_month')
                                },attrs:{"value":_vm.cycle_month,"type":"month","placeholder":"Select Month","format":"MM"},on:{"on-change":_vm.cycleMonthChange}}),(_vm.errors.has('cycle_month'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_month'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"col-label form-label tw-text-xs required"},[_vm._v(_vm._s(_vm.$t('nssfReport.exchangeRate')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.exchange_rate),expression:"exchange_rate"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('exchange_rate')
                            },attrs:{"type":"text"},domProps:{"value":(_vm.exchange_rate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.exchange_rate=$event.target.value}}}),(_vm.errors.has('exchange_rate'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('exchange_rate'))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh tw-mt-3"},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('export'))+" ")]),_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":_vm.fetchData}},[_vm._v(" "+_vm._s(_vm.$t('view'))+" ")])],1)])]),_c('div',{staticClass:"col-md-9 mt-3"},[_c('Table',{staticClass:"tw-rounded-md",attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"wage_khmer",fn:function(ref){
                            var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(_vm.formatNumber(row.wage_khmer)))])]}},{key:"wage_dollar",fn:function(ref){
                            var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(_vm.formatNumber(row.wage_dollar)))])]}},{key:"assum",fn:function(ref){
                            var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(_vm.formatNumber(row.assum)))])]}},{key:"contribution",fn:function(ref){
                            var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(_vm.formatNumber(row.contribution)))])]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }